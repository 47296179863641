import { getAdvances, getTransactions } from '@/services/transactions'

export async function checkTransactionReadyForFinalInvoice (propertyId) {
  const transactionsResponse = await getTransactions({ property: propertyId })
  const transactions = transactionsResponse.data?.results
  const transaction = transactions[0]

  const suspensiveConditionsReady = !transaction?.ovab
  const hasSuspensiveCondition = suspensiveConditionsReady && transaction?.ovab_passed

  const advancesResponse = await getAdvances({ params: { property: propertyId, exclude_finished_advances: false } })
  const advances = advancesResponse.data.results

  const hasAdvance = advances.length > 0
  const advanceReady = hasAdvance && advances.every(advance => parseFloat(advance.balance) === 0)

  let showModal = false
  if (hasAdvance && hasSuspensiveCondition) {
    showModal = advanceReady && suspensiveConditionsReady
  } else {
    showModal = (hasSuspensiveCondition && suspensiveConditionsReady) || (hasAdvance && advanceReady)
  }

  return {
    // Show modal if there is a suspensive condition and it is ready or if there are advances and they all are payed off
    showModal: showModal,
    modalData: {
      propertyId,
      hasSuspensiveCondition,
      suspensiveConditionsReady,
      hasAdvance,
      advanceReady
    }
  }
}

export async function getMandateType (propertyId) {
  const transactionsResponse = await getTransactions({ property: propertyId })
  const transactions = transactionsResponse.data?.results
  const transaction = transactions[0]
  return transaction?.mandate_type || null
}
