<template>
  <BaseModal ref='modal' title="Voorschot bewerken">
    <FormulateForm
      v-model="values"
      name="updateSalesAdvanceForm"
      @submit="submit"
    >
      <SalesAdvanceForm
        :advance="advance"
        :values="values"
        :soldByCoBroker="advance.sold_by === 'co-broker'"
        :thirdPartyAccounts="thirdPartyAccounts"
        :isUpdate="true"
        @advance-payment-account-changed="advancePaymentAccountChanged"
      />
      <FormulateInput
        type="submit"
        title="Opslaan"
        outer-class="tw-my-4 tw-float-right"
      >
        Opslaan
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { successModal, questionModal } from '@/modalMessages'
import { updateAdvance, deleteAdvance } from '@/services/transactions'
import { updateProperty } from '@/services/properties'
import SalesAdvanceForm from '@/components/transactions/SalesAdvanceForm'
import {
  ADVANCE_PAYMENT_ACCOUNT_DEWAELE,
  ADVANCE_PAYMENT_ACCOUNT_NONE,
  ADVANCE_PAYMENT_ACCOUNT_NOTARY
} from '@/utils/helpers'
export default {
  name: 'UpdateSalesAdvanceModal',
  components: {
    SalesAdvanceForm
  },
  props: {
    thirdPartyAccounts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      advance: {},
      values: {}
    }
  },
  methods: {
    show (advance) {
      this.advance = advance
      this.values = {
        advance_payment_account: 1,
        should_deduct_sales_fee_from_advance_payment: advance.should_deduct_sales_fee_from_advance_payment,
        advance_payment: advance.amount,
        third_party_account: advance.account
      }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    validateAmountGtZero ({ getFormValues }) {
      const value = getFormValues().amount
      return parseFloat(value) >= 0
    },
    validateAmountLtBalance ({ getFormValues }) {
      const value = getFormValues().amount
      return parseFloat(value) <= parseFloat(this.advance.balance)
    },
    advancePaymentAccountChanged (value) {
      if (parseInt(this.values.advance_payment_account) !== 1) {
        this.values.should_deduct_sales_fee_from_advance_payment = false
      }
    },
    async updateAdvance (data) {
      try {
        const payload = {
          account: data.third_party_account,
          amount: data.advance_payment,
          should_deduct_sales_fee_from_advance_payment: data.should_deduct_sales_fee_from_advance_payment
        }
        const response = await updateAdvance(this.advance.id, payload)
        return response
      } catch (error) {
        this.$formulate.handle(error, 'payAdvanceForm')
      }
    },
    async deleteAdvance () {
      try {
        await deleteAdvance(this.advance.id)
      } catch (error) {
        console.error(error)
      }
    },
    async submit (data) {
      let updateResponse = null
      let deleteResponse = null
      if (parseInt(data.advance_payment_account) === ADVANCE_PAYMENT_ACCOUNT_DEWAELE) {
        updateResponse = await this.updateAdvance(data)
      } else {
        if (this.advance.amount !== this.advance.balance) {
          // This means part of the advance was already paid to a third party account
          const result = await questionModal(
            `Er werd al een deel van het voorschot betaald op een derdenrekening.
            Weet je zeker dat je het voorschot wilt ${parseInt(data.advance_payment_account) === ADVANCE_PAYMENT_ACCOUNT_NONE ? 'verwijderen' : 'wijzigen'}?`
          )
          if (!result.value) return
        }
        let payload = {
          advance_payment_account: null,
          advance_payment: null
        }
        if (parseInt(data.advance_payment_account) === ADVANCE_PAYMENT_ACCOUNT_NOTARY) {
          payload = {
            advance_payment_account: data.advance_payment_account,
            advance_payment: data.advance_payment
          }
        }
        updateResponse = await updateProperty(this.advance.property, payload)
        deleteResponse = await this.deleteAdvance()
        this.hide()
      }
      successModal('Het voorschot werd aangepast.')
      this.$emit('updated', this.advance.property)
      this.hide()
      return { updateResponse, deleteResponse }
    }
  }
}
</script>
